/* --- NPM --- */
import React, { Component } from 'react';
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import ReactReadMoreReadLess from "react-read-more-read-less";

import Header from '../components/Header';
import Arrows from '../images/novice/arrows.svg';

import translations from "../translations/novice";

export default class Novice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: { name: 'Od najnovejšega do najstarejšega', value: "1" },
      open: false,
      novice: [],
      header: {},
      body: {
        back: "Nazaj",
        novQuery: [
          { name: 'Od najnovejšega do najstarejšega', value: "1" },
          { name: 'Od najstarejšega do najnovejšega', value: "2" }
        ],
        more: "Preberi več",
        less: "Preberi manj"
      }
    };
    this.changeSelected = this.changeSelected.bind(this);
    this.getNews = this.getNews.bind(this);
  }
  async componentDidMount() {


    switch (this.props.language.lang) {
      case 'si':
        this.setState({ header: translations.si.header, body: translations.si.body, selected: translations.si.body.novQuery[0] });
        break;
      case 'en':
        this.setState({ header: translations.en.header, body: translations.en.body, selected: translations.en.body.novQuery[0] });
        break;
      case 'de':
        this.setState({ header: translations.de.header, body: translations.de.body, selected: translations.de.body.novQuery[0] });
        break;
      default:
        this.setState({ header: translations.si.header, body: translations.si.body, selected: translations.si.body.novQuery[0] });
        break;
    }
    await this.getNews("type1");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      switch (this.props.language.lang) {
        case 'si':
          translations.si.body.novQuery.forEach(e => {
            if (this.state.selected.value === e.value) {
              let newNovice = this.state.novice;
              newNovice.forEach(novica => {
                novica.title = novica.title_si;
                novica.text = novica.text_si;
              });
              this.setState({ header: translations.si.header, body: translations.si.body, selected: e, novice: newNovice });
            }
          });
          break;
        case 'en':
          translations.en.body.novQuery.forEach(e => {
            if (this.state.selected.value === e.value) {
              let newNovice = this.state.novice;
              newNovice.forEach(novica => {
                novica.title = novica.title_en;
                novica.text = novica.text_en;
              });
              this.setState({ header: translations.en.header, body: translations.en.body, selected: e, novice: newNovice });
            }
          });
          break;
        case 'de':
          translations.de.body.novQuery.forEach(e => {
            if (this.state.selected.value === e.value) {
              let newNovice = this.state.novice;
              newNovice.forEach(novica => {
                novica.title = novica.title_de;
                novica.text = novica.text_de;
              });
              this.setState({ header: translations.de.header, body: translations.de.body, selected: e, novice: newNovice });
            }
          });
          break;
        default:
          translations.si.body.novQuery.forEach(e => {
            if (this.state.selected.value === e.value) {
              let newNovice = this.state.novice;
              newNovice.forEach(novica => {
                novica.title = novica.title_si;
                novica.text = novica.text_si;
              });
              this.setState({ header: translations.si.header, body: translations.si.body, selected: e, novice: newNovice });
            }
          });
          break;
      }
    }
  }

  async changeSelected(selected) {
    if (selected.value === "1") {
      await this.getNews("type1");
    } else {
      await this.getNews("type2");
    }
    this.setState({ selected: selected, open: !this.state.open });

  }

  async getNews(type) {
    const response = await fetch('/api/novice', {
      method: "POST",
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        type: type
      })
    });
    const body = await response.json();
    if (body.success) {
      body.novice.forEach(novica => {

        switch (this.props.language.lang) {
          case 'si':
            novica.title = novica.title_si;
            novica.text = novica.text_si;

            break;
          case 'en':
            novica.title = novica.title_en;
            novica.text = novica.text_en;
            break;
          case 'de':
            novica.title = novica.title_de;
            novica.text = novica.text_de;
            break;
          default:
            novica.title = novica.title_si;
            novica.text = novica.text_si;
            break;
        }
      });
      this.setState({ novice: body.novice });

    } else {
      console.log("napaka");
    }
  }

  textWithLinks(text) {
    const linkPattern = /\{link:(.*?);(.*?)\}/g;

    const transformedText = text.replace(linkPattern, (match, url, linkText) => {
      return `<a href="${url}" class="text-blue-500">${linkText}</a>`;
    });

    return <div dangerouslySetInnerHTML={{ __html: transformedText }} />;
  }


  checkForLinkInText(inputText) {
    const linkRegex = /\{link:([^\}]+)\}/g;
    return linkRegex.test(inputText);
  };

  render() {

    return (
      <div className="min-h-screen">
        <Header
          header={this.state.header}
        />
        <div className="mx-5 md:mx-40">
          <div className="flex justify-between my-20 pb-32 border-b-2 border-[#F5F5F5]">
            <a href="/" className="flex items-center gap-2"><ChevronLeftIcon className="w-4 h-auto cursor-pointer" />{this.state.body.back}</a>
            <div className="">
              <div className="flex border items-center cursor-pointer place-self-center border-[#6D6D6D] rounded px-2 md:px-6 py-2 ml-5" onClick={() => this.setState({ open: !this.state.open })}>
                <img src={Arrows} alt="" className="w-4 h-auto mr-2 cursor-pointer md:mr-4" />
                <button className="text-[#6D6D6D] sm:whitespace-nowrap">{this.state.selected.name}</button>
                <div className="block" >{this.state.open ? <ChevronUpIcon className="w-4 h-auto cursor-pointer ml-2 md:ml-4 text-[#6D6D6D]" /> : <ChevronDownIcon className="w-4 h-auto cursor-pointer ml-2 md:ml-4 text-[#6D6D6D]" />}</div>
              </div>
              <div className={`${this.state.open ? "flex flex-col" : "hidden"} p-2 bg-c-white rounded absolute w-auto ml-5 px-4 md:px-10`}>
                {this.state.body.novQuery.map(item => (
                  <button key={item.name} onClick={() => this.changeSelected(item)} className={`${this.state.selected.name === item.name && "bg-[#424242] text-white"} px-4 py-2 rounded font-medium`}>
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {this.state.novice.map(novica => (
            <div key={novica.key} className="flex flex-col md:flex-row mb-20 pb-20 border-b-2 border-[#F5F5F5] w-full md:items-start items-center">
              <img src={novica.image} className="w-[80%] md:w-1/2 h-full" alt="" />
              <div className="relative w-auto mt-20 md:ml-20 md:mt-0">
                <p className="mb-5 text-xl font-semibold">{novica.title}</p>
                <p className="mb-5 text-base font-normal">{`${novica.day}/${novica.month}/${novica.year}`}</p>
                <div className="text-sm text-[#6D6D6D] mb-10">
                  {
                    this.checkForLinkInText(novica.text) ?
                      this.textWithLinks(novica.text) : <ReactReadMoreReadLess
                        charLimit={450}
                        readMoreText={this.state.body.more}
                        readLessText={this.state.body.less}
                        readMoreClassName={"font-semibold absolute left-0 bottom-0 text-black"}
                        readLessClassName={"font-semibold absolute left-0 bottom-0 text-black"}
                      >
                        {novica.text}
                      </ReactReadMoreReadLess>
                  }

                </div>

              </div>
            </div>
          ))}

        </div>

      </div>
    );
  }
}
