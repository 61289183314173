import HeaderImage from '../images/header-6.png'

const translations = {
    si: {
        header: { name: 'Novice', text: 'Novice', subtext: 'Novosti v podjetju.', image: HeaderImage, button: "" },
        body: {
            back: "Nazaj",
            novQuery: [
                { name: 'Od najnovejšega do najstarejšega', value: "1" },
                { name: 'Od najstarejšega do najnovejšega', value: "2" }
            ],
            more: "Preberi več",
            less: "Preberi manj"
        }
    },
    en: {
        header: { name: 'News', text: 'News', subtext: 'News in the company.', image: HeaderImage, button: "" },
        body: {
            back: "Back",
            novQuery: [
                { name: 'From newest to oldest', value: "1" },
                { name: 'From oldest to newest', value: "2" }
            ],
            more: "Read more",
            less: "Read less"
        }
    },
    de: {
        header: { name: 'Nachrichten', text: 'Nachrichten', subtext: 'Neuigkeiten im Unternehmen.', image: HeaderImage, button: "" },
        body: {
            back: "Geh zurück",
            novQuery: [
                { name: 'Vom Neuesten zum Ältesten', value: "1" },
                { name: 'Vom Ältesten zum Neuesten', value: "2" }
            ],
            more: "Weiterlesen",
            less: "Lese weniger"
        }
    }
}

export default translations