/* --- NPM --- */
import React, { Component } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'

import Header from '../components/Header'

import S1 from '../images/stroji/stroj1.png'
import S2 from '../images/stroji/stroj2.png'
import S3 from '../images/stroji/stroj3.png'
import S4 from '../images/stroji/stroj4.png'
import S5 from '../images/stroji/stroj5.png'

import translations from "../translations/stroji";

export default class Stroji extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: { name: "Ozobljenje", image: S1, category: "K1" },
      open: false,
      loading: false,
      stroji: [],
      header: {},
      body: {
        s1: [
          { name: "Ozobljenje", image: S1, category: "K1" },
          { name: "Struženje in rezkanje", image: S2, category: "K2" },
          { name: "Brušenje", image: S3, category: "K3" },
          { name: "Pehanje", image: S4, category: "K4" },
          { name: "Razrez", image: S5, category: "K5" },
        ]
      }
    }
    this.getStroji = this.getStroji.bind(this)
    this.changeSelected = this.changeSelected.bind(this)
  }

  async componentDidMount() {
    switch (this.props.language.lang) {
      case 'si':
        this.setState({ header: translations.si.header, body: translations.si.body, selected: translations.si.body.s1[0] })
        break;
      case 'en':
        this.setState({ header: translations.en.header, body: translations.en.body, selected: translations.en.body.s1[0] })
        break;
      case 'de':
        this.setState({ header: translations.de.header, body: translations.de.body, selected: translations.de.body.s1[0] })
        break;
      default:
        this.setState({ header: translations.si.header, body: translations.si.body, selected: translations.si.body.s1[0] })
        break;
    }

    await this.getStroji()

  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      switch (this.props.language.lang) {
        case 'si':
          translations.si.body.s1.forEach(e => {
            if (this.state.selected.category === e.category) {
              this.setState({ header: translations.si.header, body: translations.si.body, selected: e })
            }
          })

          break;
        case 'en':
          translations.en.body.s1.forEach(e => {
            if (this.state.selected.category === e.category) {
              this.setState({ header: translations.en.header, body: translations.en.body, selected: e })
            }
          })
          break;
        case 'de':
          translations.de.body.s1.forEach(e => {
            if (this.state.selected.category === e.category) {
              this.setState({ header: translations.de.header, body: translations.de.body, selected: e })
            }
          })
          break;
        default:
          translations.si.body.s1.forEach(e => {
            if (this.state.selected.category === e.category) {
              this.setState({ header: translations.si.header, body: translations.si.body, selected: e })
            }
          })
          break;
      }
    }




  }

  async getStroji() {
    const response = await fetch('/api/stroji', {
      method: "GET",
      headers: {
        'content-type': 'application/json'
      }
    })
    const body = await response.json()
    if (body.success) {
      this.setState({ stroji: body.stroji })
      
    } else {
      console.log("napaka")
    }
  }

  changeSelected(selected, mobile) {
    mobile ? this.setState({ selected: selected, open: !this.state.open }) : this.setState({ selected: selected })
  }

  render() {

    return (
      <div className="min-h-screen">
        <Header
          header={this.state.header}
        />
        <div className="relative" data-aos="fade-in">
          <div className="hidden md:flex flex-row w-[80%] lg:w-2/3 2lg:w-1/2 relative left-1/2 transform -translate-x-1/2 justify-between my-10">
            {this.state.body.s1.map(item => (
              <button key={item.name} onClick={() => this.changeSelected(item, false)} className={`${this.state.selected.name === item.name && "bg-[#424242] text-white font-semibold"} px-4 py-2 rounded font-medium`}>
                {item.name}
              </button>
            ))}
          </div>
          <div className="flex md:hidden flex-row relative left-1/2 transform -translate-x-1/2 w-fit py-1 px-3 rounded bg-[#424242] mt-10" onClick={() => this.setState({ open: !this.state.open })}>
            <button className="text-white font-semibold">{this.state.selected.name}</button>
            <div className="block md:hidden">{this.state.open ? <ChevronUpIcon className="w-7 h-auto cursor-pointer text-white" /> : <ChevronDownIcon className="w-7 h-auto cursor-pointer text-white" />}</div>
          </div>
          <div className={`${this.state.open ? "flex flex-col md:hidden" : "hidden"} absolute left-1/2 transform -translate-x-1/2 p-2 bg-c-white rounded`}>
            {this.state.body.s1.map(item => (
              <button key={item.name} onClick={() => this.changeSelected(item, true)} className={`${this.state.selected.name === item.name && "bg-[#424242] text-white font-semibold"} px-4 py-2 rounded font-medium whitespace-nowrap`}>
                {item.name}
              </button>
            ))}
          </div>
        </div>



        <div className="flex flex-col md:flex-row mx-5 md:mx-40 justify-evenly md:justify-between mb-48 mt-20 items-center md:items-start">
          <div className="mb-10 md:mb-0 text-left mr-10">
            <p className="text-black font-medium text-3xl mb-10">{this.state.body.s2}</p>
            <div>
              {this.state.stroji.map(stroj => {
                
                if (stroj.category === this.state.selected.category) {
                  return (
                    <div className="border-b border-[#F5F5F5] pb-2 mb-2 last:border-none">
                      {stroj.name}
                    </div>
                  )
                } else {
                  return (null)
                }
              }

              )}
            </div>

          </div>

          <img className="w-full sm:w-2/3 md:w-[60%] h-full " src={this.state.selected.image} alt="" />

        </div>


      </div>
    )
  }
}
