import HeaderImage from '../images/header-3.png'
import S1 from '../images/storitve/str1.svg'
import S2 from '../images/storitve/str2.svg'
import S3 from '../images/storitve/str3.svg'
import S4 from '../images/storitve/str4.svg'
import S5 from '../images/storitve/str5.svg'

const translations = {
    si: {
        header: { name: 'Storitve', text: 'Storitve', subtext: 'Razpoložljiv strojni park nam omogoča izvajanje spodaj navedenih storitev.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { title: 'Ozobljenje', subtext: 'Cilindrično, stožno, zobate jermenice, zobate letve, verižniki, polži in polžna kolesa', image: S1 },
                { title: 'Struženje', subtext: 'Klasično, CNC', image: S2 },
                { title: 'Rezkanje', subtext: 'Klasično, CNC', image: S3 },
                { title: 'Brušenje', subtext: 'Cilindrično, ploskovno, žlebovi, položne navojnice, rezilna orodja', image: S4 },
                { title: 'Pehanje utorov', subtext: 'Eno utorno, več utorno', image: S5 },
            ]
        }
    },
    en: {
        header: { name: 'Services', text: 'Services', subtext: 'The available machine park allows us to perform the services listed below.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { title: 'Teething', subtext: 'Cylindrical, conical, toothed pulleys, toothed racks, sprockets, worms and worm wheels', image: S1 },
                { title: 'Turning', subtext: 'Classic, CNC', image: S2 },
                { title: 'Milling', subtext: 'Classic, CNC', image: S3 },
                { title: 'Grinding', subtext: 'Cylindrical, flat, grooves, flat threads, cutting tools', image: S4 },
                { title: 'Grooving', subtext: 'One grooved, multiple grooved', image: S5 },
            ]
        }
    },
    de: {
        header: { name: 'Dienstleistungen', text: 'Dienstleistungen', subtext: 'Der vorhandene Maschinenpark ermöglicht uns die Durchführung der unten aufgeführten Dienstleistungen.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { title: 'Zahnen', subtext: 'Zylindrische, konische, Zahnriemenscheiben, Zahnstangen, Kettenräder, Schnecken und Schneckenräder', image: S1 },
                { title: 'Drehen', subtext: 'Klassisch, CNC', image: S2 },
                { title: 'Mahlen', subtext: 'Klassisch, CNC', image: S3 },
                { title: 'Mahlen', subtext: 'Zylindrisch, flach, Rillen, Flachgewinde, Schneidwerkzeuge', image: S4 },
                { title: 'Nuten', subtext: 'Einmal gerillt, mehrfach gerillt', image: S5 },
            ]
        }
    }
}

export default translations