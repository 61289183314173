import HeaderImage from '../images/header-4.png';
import S1 from '../images/stroji/stroj1.png';
import S2 from '../images/stroji/stroj2.png';
import S3 from '../images/stroji/stroj3.png';
import S4 from '../images/stroji/stroj4.png';
import S5 from '../images/stroji/stroj5.png';

const translations = {
    si: {
        header: { name: 'Stroji', text: 'Stroji', subtext: 'Trenutno razpoložljivi stroji nam omogočajo, da smo prilagodljivi in znamo poiskati ustrezno rešitev ne glede na strankine specifične zahteve. Prizadevamo si, da s posodabljanjem strojne opreme in nakupom novih strojev ostajamo konkurenčni na domačem trgu.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { name: "Ozobljenje", image: S1, category: "K1" },
                { name: "Struženje in rezkanje", image: S2, category: "K2" },
                { name: "Brušenje", image: S3, category: "K3" },
                { name: "Pehanje", image: S4, category: "K4" },
                { name: "Razrez", image: S5, category: "K5" },
            ],
            s2: "Stroji"
        }
    },
    en: {
        header: { name: 'Machines', text: 'Machines', subtext: "The currently available machines allow us to be flexible and know how to find a suitable solution regardless of the customer's specific requirements. We strive to remain competitive on the domestic market by updating hardware and purchasing new machines.", image: HeaderImage, button: "" },
        body: {
            s1: [
                { name: "Teething", image: S1, category: "K1" },
                { name: "Turning and milling", image: S2, category: "K2" },
                { name: "Grinding", image: S3, category: "K3" },
                { name: "Grooving", image: S4, category: "K4" },
                { name: "Cutting", image: S5, category: "K5" },
            ],
            s2: "Machines"
        }
    },
    de: {
        header: { name: 'Maschinen', text: 'Maschinen', subtext: 'Die aktuell verfügbaren Maschinen ermöglichen es uns, flexibel zu sein und unabhängig von den spezifischen Anforderungen des Kunden eine geeignete Lösung zu finden. Wir bemühen uns, auf dem heimischen Markt wettbewerbsfähig zu bleiben, indem wir Hardware aktualisieren und neue Maschinen kaufen.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { name: "Zahnen", image: S1, category: "K1" },
                { name: "Drehen und Fräsen", image: S2, category: "K2" },
                { name: "Mahlen", image: S3, category: "K3" },
                { name: "Mahlenn", image: S4, category: "K4" },
                { name: "Einschnitt", image: S5, category: "K5" },
            ],
            s2: "Maschinen"
        }
    }
};

export default translations;