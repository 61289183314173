import { SiGooglemaps } from 'react-icons/si';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';

export default function Footer() {
    return (
        <div className="h-20 bg-[#424242] relative flex flex-row w-full justify-center items-center">
            <a className="ml-5 text-white text-sm hover:underline" rel="noreferrer" target="_blank" href="https://maps.google.com/maps?ll=46.348669,15.404327&amp;z=15&amp;t=m&amp;hl=sl-SI&amp;gl=US&amp;mapclient=apiv3&amp;cid=12994958974210528399"><SiGooglemaps className="w-6 h-6"/></a>
            <div className="mx-10 sm:mx-32 text-white">{`© ${new Date().getFullYear()} Tehovnik d.o.o.`}</div>
            <a className="mr-5 text-white" href="tel:+386037593880"><BsFillTelephoneOutboundFill className="w-5 h-5"/></a><br />
        </div>
    )
}