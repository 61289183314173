import HeaderImage from '../images/header-2.png'

import I1 from '../images/izdelki/izd1.svg'
import I2 from '../images/izdelki/izd2.svg'
import I3 from '../images/izdelki/izd3.svg'
import I4 from '../images/izdelki/izd4.svg'
import I5 from '../images/izdelki/izd5.svg'
import I6 from '../images/izdelki/izd6.svg'

const translations = {
    si: {
        header: { name: 'Izdelki in prodaja', text: 'Izdelki in prodaja', subtext: 'Izdelke vam naredimo na podlagi tehnične risbe ali vzorca po meri, ki ustreza vašim potrebam in zahtevam. Prav tako vam svetujemo pri izbiri materialov in mazalnih sredstev za čim daljšo življenjsko dobo izdelka.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { image: I1, title: 'Cilindrični zobniki', sub1: 'Zunanje ravno in poševno ozobljeni', m1: '(max fi=600, m=12)', sub2: 'Notranje ravno ozobljeni', m2: '(max fi=1000, m=12)' },
                { image: I2, title: 'Zobate jermenice', sub1: 'Metrične', m1: '(max fi=600)', sub2: 'Colske', m2: '(max fi=600)' },
                { image: I3, title: 'Zobate letve', sub1: 'Ravno ozobljene', m1: '(150x150x2000, m=18)', sub2: 'Poševno ozobljene', m2: '(150x150x2000, m=18)' },
                { image: I4, title: 'Spiralno stožna dvojica', sub1: 'Ravno ozobljeni', m1: '(max fi=250, m=6)', sub2: 'Spiralno ozobljeni', m2: '(max fi=500, m=10)' },
                { image: I5, title: 'Gredi in osovine', sub1: 'Ravno žlebovi', m1: '(fi=230×1500)', sub2: 'Evolventni žlebovi', m2: '(fi=230×1500)' },
                { image: I6, title: 'Polži in polžna kolesa', sub1: 'Polžna kolesa', m1: '(max fi=500, m=10)', sub2: 'Polži', m2: '(max fi=150, L=600, m=10)' }
            ],
            s2: {
                h1: "Olja in maziva",
                t1: "Za uporabo v pogonski tehniki in hidravličnih sklopih",
                h2: "Tehnični spreji",
                t2: "Za obnovo in vzdrževanje delov",
                h3: "Čistila",
                t3: "Za čiščenje talnih površin in orodja"
            },
            h1: "Izdelki"
        }
    },
    en: {
        header: { name: 'Products and sales', text: 'Products and sales', subtext: 'We make products for you based on a technical drawing or custom sample that meets your needs and requirements. We also advise you about the choice of materials and lubricants for the longest possible life of the product.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { image: I1, title: 'Cylindrical gears', sub1: 'Externally flat and obliquely toothed', m1: '(max fi=600, m=12)', sub2: 'Internally flat toothed', m2: '(max fi=1000, m=12)' },
                { image: I2, title: 'Toothed pulleys', sub1: 'Metric', m1: '(max fi=600)', sub2: 'Inch', m2: '(max fi=600)' },
                { image: I3, title: 'Dental moldings', sub1: 'Straight toothed', m1: '(150x150x2000, m=18)', sub2: 'Oblique toothed', m2: '(150x150x2000, m=18)' },
                { image: I4, title: 'Spiral-conical pair', sub1: 'Straight toothed', m1: '(max fi=250, m=6)', sub2: 'Spiral toothed', m2: '(max fi=500, m=10)' },
                { image: I5, title: 'Shafts and axles', sub1: 'Straight gutters', m1: '(fi=230×1500)', sub2: 'Involute grooves', m2: '(fi=230×1500)' },
                { image: I6, title: 'Worms and worm wheels', sub1: 'Worm wheels', m1: '(max fi=500, m=10)', sub2: 'Worms', m2: '(max fi=150, L=600, m=10)' }
            ],
            s2: {
                h1: "Oils and lubricants",
                t1: "For use in drive technology and hydraulic assemblies",
                h2: "Technical sprays",
                t2: "For the restoration and maintenance of parts",
                h3: "Cleaners",
                t3: "For cleaning floor surfaces and tools"
            },
            h1: "Products"
        }
    },
    de: {
        header: { name: 'Produkte und Vertrieb', text: 'Produkte und Vertrieb', subtext: 'Wir fertigen für Sie Produkte auf der Grundlage einer technischen Zeichnung oder eines Kundenmusters, das Ihren Bedürfnissen und Anforderungen entspricht. Wir beraten Sie auch bei der Auswahl der Materialien und der Schmierstoffe für eine möglichst lange Lebensdauer des Produkts.', image: HeaderImage, button: "" },
        body: {
            s1: [
                { image: I1, title: 'Stirnräder', sub1: 'Außen flach und schräg gezahnt', m1: '(max fi=600, m=12)', sub2: 'Innen flach gezahnt', m2: '(max fi=1000, m=12)' },
                { image: I2, title: 'Zahnscheiben', sub1: 'Metrisch', m1: '(max fi=600)', sub2: 'Zoll', m2: '(max fi=600)' },
                { image: I3, title: 'Zahnformteile', sub1: 'Gerade verzahnt', m1: '(150x150x2000, m=18)', sub2: 'Schräg gezahnt', m2: '(150x150x2000, m=18)' },
                { image: I4, title: 'Spiralkonisches Paar', sub1: 'Gerade verzahnt', m1: '(max fi=250, m=6)', sub2: 'Spiralverzahnt', m2: '(max fi=500, m=10)' },
                { image: I5, title: 'Wellen und Achsen', sub1: 'Gerade Dachrinnen', m1: '(fi=230×1500)', sub2: 'Evolventenrillen', m2: '(fi=230×1500)' },
                { image: I6, title: 'Schnecken und Schneckenräder', sub1: 'Schneckenräder', m1: '(max fi=500, m=10)', sub2: 'Schnecken', m2: '(max fi=150, L=600, m=10)' }
            ],
            s2: {
                h1: "Öle und Schmiermittel",
                t1: "Für den Einsatz in der Antriebstechnik und hydraulischen Baugruppen",
                h2: "Technische Sprays",
                t2: "Für die Restaurierung und Wartung von Teilen",
                h3: "Reiniger",
                t3: "Zum Reinigen von Bodenflächen und Werkzeugen"
            },
            h1: "Produkte"
        }
    }
}

export default translations