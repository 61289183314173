import HeaderImage from '../images/header-1-1.png'

const translations = {
    si: {
        header: { name: 'Domov', text: 'Zagotavljanje kakovostnih tehnoloških rešitev za vaš uspeh.', subtext: '', image: HeaderImage, button: "Preberi novice" },
        body: {
            s1: [
                { text: 'Izkušnje', subtext: 'Naše proizvode in storitve odlikujejo kvaliteta in natančnost, ki je rezultat strokovne usposobljenosti zaposlenih, znanja, dolgoletnih izkušenj in sodobne strojne opreme, ki jo uporabljamo.' },
                { text: 'Znanje', subtext: 'Smo zanesljiv naslov za izdelavo raznovrstnih produktov po vaši meri, prav tako pa nudimo tudi strokovno svetovanje, ki našim strankam omogoča, da ostajajo konkurenčne na današnjem trgu.' },
                { text: 'Vizija', subtext: 'Tudi v bodoče bomo vaš najbolj zanesljivi partner, saj si prizadevamo biti vodilni ponudnik tehnoloških rešitev in storitev, ki svoje stranke in kvaliteto vedno postavlja na prvo mesto.' }
            ],
            s2: {
                h1: "O podjetju",
                t1: "Smo proizvodno in trgovsko podjetje z več kot 50- letnim delovanjem. Prvovrstno se ukvarjamo z izdelavo in obdelavo zobnikov ter ozobljenja. Nudimo usluge struženja, brušenja, rezkanja in pehanja ozobljenja ter v povezavi z drugimi podjetji nudimo termično obdelavo. Proizvodne kapacitete so prilagojene posamični proizvodnji ter manjšim serijam. V trgovskem delu nudimo strankam in naročnikom olja, maziva ter razna tehnična sredstva, pri prodaji pa se ukvarjamo med drugim še s prodajo rabljenih strojev in naprav.",
                t2: "V podjetju izpolnjujemo zadane cilje na področju modernizacije strojnega parka in s tem stremimo k nudenju kakovostnejših končnih izdelkov in storitev. Po nekajkratni rasti smo danes v prihodnost zazrto družinsko podjetje, ki temeljimo na petdesetletni tradiciji in smo prepoznaven naslov na domačem trgu za izdelavo zobnikov in raznoraznih strojnih elementov."
            },
            s3: {
                h1: 50,
                t1: "Let delovanja",
                h2: 11,
                t2: "Zaposlenih",
                h3: 50,
                t3: "Strojev",
                h4: 2000,
                t4: "Strank",
                h5: 1000,
                t5: "Projektov letno"
            },
            s4: {
                h1: "Naše stranke",
                t1: "Naši izdelki se uporabljajo v različnih panogah in zato sodelujemo s podjetji, ki delujejo na področjih kot so na primer strojna industrija, vzdrževanje in servisi, farmacevtska industrija, kemična industrija, grafična industrija, prehrambena industrija in kmetijstvo. Predani smo temu, da našim strankam vedno nudimo najvišjo kvaliteto izdelkov in storitev. Zato nam je v ponos, da nam zaupate in se vedno znova odločate za sodelovanje z nami."
            }
        }
    },
    en: {
        header: { name: 'Home', text: 'Ensuring high quality technological solutions for your success', subtext: '', image: HeaderImage, button: "Read the news" },
        body: {
            s1: [
                { text: 'Experience', subtext: 'Our products and services are characterized by quality and precision, which is the result of the professional training of our employees, knowledge, many years of experience and the modern hardware we use.' },
                { text: 'Knowledge', subtext: "We are a reliable address for the manufacture of a variety of customized products, and we also offer professional advice that allows our customers to remain competitive in today's market." },
                { text: 'Vision', subtext: 'We will also be your most reliable partner in the future, as we strive to be a leading provider of technological solutions and services that always puts its customers and quality first.' }
            ],
            s2: {
                h1: "About the company",
                t1: "We are a production and trading company with more than 50 years of experience. We primarily deal with the production and processing of gears and teeth. We offer turning, grinding, milling and gear grinding services, and in cooperation with other companies we offer heat treatment. Production capacities are adapted to individual production and smaller batches. In the commercial part, we offer customers and clients oils, lubricants and various technical means, while in sales we also deal with the sale of used machines and devices.",
                t2: "In the company, we are meeting the set goals in the field of modernization of the machinery park and thereby striving to offer higher quality end products and services. After several times of growth, today we are a future-oriented family company, based on a fifty-year tradition, and we are a recognizable address on the domestic market for the production of gears and various machine elements."
            },
            s3: {
                h1: 50,
                t1: "Year of operation",
                h2: 11,
                t2: "Employees",
                h3: 50,
                t3: "Machines",
                h4: 2000,
                t4: "Customers",
                h5: 1000,
                t5: "Projects per year"
            },
            s4: {
                h1: "Our customers",
                t1: "Our products are used in various industries, and that is why we cooperate with companies operating in areas such as the machine industry, maintenance and services, pharmaceutical industry, chemical industry, graphic industry, food industry and agriculture. We are committed to always providing our customers with the highest quality products and services. That is why we are proud that you trust us and choose to cooperate with us again and again."
            }
        }
    },
    de: {
        header: { name: 'Heimat', text: 'Gewährleistung qualitativ hochwertiger Technologielösungen für Ihren Erfolg', subtext: '', image: HeaderImage, button: "Lies die Nachrichten" },
        body: {
            s1: [
                { text: 'Erfahrung', subtext: 'Unsere Produkte und Dienstleistungen zeichnen sich durch Qualität und Präzision aus, die das Ergebnis der professionellen Ausbildung unserer Mitarbeiter, des Wissens, der langjährigen Erfahrung und der von uns eingesetzten modernen Hardware ist.' },
                { text: 'Wissen', subtext: 'Wir sind eine zuverlässige Adresse für die Herstellung einer Vielzahl von kundenspezifischen Produkten und bieten auch eine professionelle Beratung, die es unseren Kunden ermöglicht, auf dem heutigen Markt wettbewerbsfähig zu bleiben.' },
                { text: 'Vision', subtext: 'Wir werden auch in Zukunft Ihr zuverlässigster Partner sein, denn wir streben danach, ein führender Anbieter von technologischen Lösungen und Dienstleistungen zu sein, bei dem der Kunde und die Qualität immer an erster Stelle stehen.' }
            ],
            s2: {
                h1: "Über das Unternehmen",
                t1: "Wir sind ein Produktions- und Handelsunternehmen mit mehr als 50-jähriger Tätigkeit. Wir beschäftigen uns hauptsächlich mit der Herstellung und Bearbeitung von Zahnrädern. Wir bieten Dreh-, Schleif-, Fräs- und Verzahnungsschleifdienstleistungen an . (nov stavek) In Zusammenarbeit mit anderen Unternehmen bieten wir auch Wärmebehandlung an. Die Produktionskapazitäten werden an die Einzelfertigung und kleinere Chargen angepasst. Im kaufmännischen Teil bieten wir Kunden und Auftraggebern Öle, Schmierstoffe und diverse technische Hilfsmittel an, während wir uns im Verkauf auch mit dem Verkauf gebrauchter Maschinen und Geräte beschäftigen.",
                t2: "Im Unternehmen erfüllen wir die gesetzten Ziele im Bereich der Modernisierung des Maschinenparks und streben dadurch danach, Endprodukte und Dienstleistungen von höherer Qualität anzubieten. Nach mehrmaligem Wachstum sind wir heute ein zukunftsorientiertes Familienunternehmen, basierend auf einer fünfzigjährigen Tradition, und eine erkennbare Adresse auf dem heimischen Markt für die Herstellung von Zahnrädern und diversen Maschinenelementen."
            },
            s3: {
                h1: 50,
                t1: "Betriebsjahr",
                h2: 11,
                t2: "Angestellte",
                h3: 50,
                t3: "Maschinen",
                h4: 2000,
                t4: "Kunden",
                h5: 1000,
                t5: "Projekte pro Jahr"
            },
            s4: {
                h1: "Unsere Kunden",
                t1: "Unsere Produkte werden in verschiedenen Branchen eingesetzt, deshalb kooperieren wir mit Unternehmen aus den Bereichen Maschinenindustrie, Wartung und Service, Pharmaindustrie, Chemische Industrie, Grafische Industrie, Lebensmittelindustrie und Landwirtschaft. Wir sind bestrebt, unseren Kunden stets Produkte und Dienstleistungen von höchster Qualität anzubieten. Deshalb sind wir stolz darauf, dass Sie uns vertrauen und sich immer wieder für eine Zusammenarbeit mit uns entscheiden."
            }
        }
    }
}

export default translations