/* --- NPM --- */
import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

/* Components */
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

/* Pages */
import Domov from "./pages/Domov";
import Izdelki from "./pages/Izdelki";
import Kontakt from "./pages/Kontakt";
import Storitve from "./pages/Storitve";
import Stroji from "./pages/Stroji";
import Novice from "./pages/Novice";

import SIflag from './images/si-flag.svg'
import ENflag from './images/en-flag.svg'
import DEflag from './images/de-flag.svg'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      languages: [
        { flag: SIflag, lang: "si" },
        { flag: ENflag, lang: "en" },
        { flag: DEflag, lang: "de" }
      ],
      selectedLang: { flag: SIflag, lang: "si" }
    }
    this.changeLang = this.changeLang.bind(this)
  }

  componentDidMount() {
    AOS.init();
    AOS.refresh();
  }

  changeLang(lang) {
    let newLang = this.state.languages[0]
    switch (lang) {
      case 'si':
        this.setState({ selectedLang: newLang })
        break;
      case 'en':
        newLang = this.state.languages[1]
        this.setState({ selectedLang: newLang })
        break;
      case 'de':
        newLang = this.state.languages[2]
        this.setState({ selectedLang: newLang })
        break;
      default:
        this.setState({ selectedLang: newLang })
        break;
    }
  }

  render() {

    return (
      <div className="font-inter">
        <BrowserRouter>
          <Navbar language={this.state.selectedLang} languages={this.state.languages} changeLang={this.changeLang} />

          <Routes>
            <Route path="/" element={<Domov language={this.state.selectedLang} />} />
            <Route path="/izdelki-in-prodaja" element={<Izdelki language={this.state.selectedLang} />} />
            <Route path="/storitve" element={<Storitve language={this.state.selectedLang} />} />
            <Route path="/stroji" element={<Stroji language={this.state.selectedLang} />} />
            <Route path="/kontakt" element={<Kontakt language={this.state.selectedLang} />} />
            <Route path="/novice" element={<Novice language={this.state.selectedLang} />} />
            <Route path="*" element={<Domov language={this.state.selectedLang} />} />
          </Routes>
          <Footer language={this.state.selectedLang} />
        </BrowserRouter>
      </div>
    )
  }
}

export default App;