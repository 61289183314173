import { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: {}
        }
    }

    render() {

        return (
            <>
                <div className="relative select-none overflow-hidden" data-aos="fade-in">
                    <div className={`overflow-hidden ${!this.props.header.button ? "min-h-[18rem]" : "min-h-[22rem]"} bg-black`}>
                        <img src={this.props.header.image} className="min-w-[80rem] w-full relative left-1/2 transform -translate-x-1/2" alt="" />
                    </div>

                    <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white flex flex-col ${this.props.header.button ? "items-left w-[calc(100vw-5rem)] md:w-[calc(100vw-20rem)]" : "items-center"} gap-4`}>
                        <div className={`lg:mt-10 ${this.props.header.button ? "text-left max-w-xl font-bold text-3xl sm:text-5xl" : "text-center italic text-3xl"}`}>
                            {this.props.header.text}
                        </div>
                        <div className="text-base text-center w-screen px-5 sm:w-full sm:px-0">
                            {this.props.header.subtext}
                        </div>

                        {this.props.header.button && <NavLink to="/novice" className="rounded-lg border-2 w-fit px-4 py-2 hover:bg-white hover:text-black cursor-pointer ease-linear duration-100 bg-[#D9D9D9]/[0.2]">{this.props.header.button}</NavLink>}
                        
                    </div>
                    {(this.props.position === 0 || this.props.position === 1 || this.props.position === 2) && <div className="absolute top-[90%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex">
                        <div className={`${this.props.position === 0 ? "bg-white" : "bg-gray-700"}  rounded-full w-2 h-2`}/>
                        <div className={`${this.props.position === 1 ? "bg-white" : "bg-gray-700"} rounded-full w-2 h-2 mx-5`}/>
                        <div className={`${this.props.position === 2 ? "bg-white" : "bg-gray-700"} rounded-full w-2 h-2`}/>
                        </div>}
                </div>
            </>
        )
    }
}