import { Component } from 'react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'


import Logo from '../images/logo-navbar.svg'



const navigation = {
    si: [
        { name: 'Domov', href: '/' },
        { name: 'Izdelki in prodaja', href: '/izdelki-in-prodaja' },
        { name: 'Storitve', href: '/storitve' },
        { name: 'Stroji', href: '/stroji' },
        { name: 'Kontakt', href: '/kontakt' }
    ],
    en: [
        { name: 'Home', href: '/' },
        { name: 'Products and Sales', href: '/izdelki-in-prodaja' },
        { name: 'Services', href: '/storitve' },
        { name: 'Machines', href: '/stroji' },
        { name: 'Contact', href: '/kontakt' }
    ],
    de: [
        { name: 'Heimat', href: '/' },
        { name: 'Produkte und Vertrieb', href: '/izdelki-in-prodaja' },
        { name: 'Dienstleistungen', href: '/storitve' },
        { name: 'Maschinen', href: '/stroji' },
        { name: 'Kontakt', href: '/kontakt' }
    ]
}





export default class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            navigation: navigation.si,
            langOpen: false
        }
    }
    componentDidMount() {
        switch (this.props.language.lang) {
            case 'si':
                this.setState({ navigation: navigation.si })
                break;
            case 'en':
                this.setState({ navigation: navigation.en })
                break;
            case 'de':
                this.setState({ navigation: navigation.de })
                break;
            default:
                this.setState({ navigation: navigation.si })
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            switch (this.props.language.lang) {
                case 'si':
                    this.setState({ navigation: navigation.si })
                    break;
                case 'en':
                    this.setState({ navigation: navigation.en })
                    break;
                case 'de':
                    this.setState({ navigation: navigation.de })
                    break;
                default:
                    this.setState({ navigation: navigation.si })
                    break;
            }
        }
    }


    render() {
        const NavLinkClasses = "px-2 py-3"
        return (
            <div className="select-none">
                {/* Desktop */}
                <div className="hidden 2lg:flex flex-row justify-between mx-12 items-center">
                    <NavLink to="/"><img className="w-[134px]" src={Logo} alt="Tehovnik" /></NavLink>
                    
                    <div className="flex flex-row xl:gap-[5rem] 2lg:gap-10">
                        {this.state.navigation.map(item => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                className={(navData) =>
                                    navData.isActive ? `font-bold ${NavLinkClasses}` : `${NavLinkClasses} font-medium`
                                }
                            >
                                <div className="">
                                    {item.name}
                                </div>
                            </NavLink>
                        ))}


                        <div>
                            <div className="flex items-center py-2 cursor-pointer" onClick={() => this.setState({ langOpen: !this.state.langOpen })}>
                                <button className="text-[#6D6D6D]"><img src={this.props.language.flag} alt="" /></button>
                                <div className="block" >{this.state.langOpen ? <ChevronUpIcon className="w-6 h-auto cursor-pointer ml-2 text-black" /> : <ChevronDownIcon className="w-6 h-auto cursor-pointer ml-2 text-black" />}</div>
                            </div>
                            <div className={`${this.state.langOpen ? "flex flex-col" : "hidden"}  py-2 bg-c-white rounded absolute z-40`}>
                                {this.props.languages.map(item => (
                                    <button key={item.lang} onClick={() => { this.props.changeLang(item.lang); this.setState({ langOpen: !this.state.langOpen }) }} className={`${this.props.language.lang === item.lang && "bg-gray-300 text-white font-semibold"} px-4 py-2 rounded font-medium whitespace-nowrap`}>
                                        <img src={item.flag} alt="" />
                                    </button>
                                ))}
                            </div>
                        </div>


                    </div>

                </div>

                {/* Mobile */}
                <div className="flex 2lg:hidden justify-between mx-8 my-2 items-center">
                <NavLink to="/"><img className="w-[134px]" src={Logo} alt="Tehovnik" /></NavLink>
                    <div className="block 2lg:hidden" onClick={() => this.setState({ open: !this.state.open })}>{this.state.open ? <XIcon className="w-10 h-auto cursor-pointer" /> : <MenuIcon className="w-10 h-auto cursor-pointer" />}</div>
                    <div className={`${this.state.open ? "flex flex-col xl:gap-[5rem] 2lg:gap-10 absolute text-right pr-4 right-0 bottom-0 top-12 w-4/12 min-w-fit bg-white z-20 items-end" : "hidden"}`}>
                        {this.state.navigation.map(item => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                onClick={() => this.setState({ open: !this.state.open })}
                                className={(navData) =>
                                    navData.isActive ? ` font-bold ${NavLinkClasses}` : `${NavLinkClasses} font-medium`
                                }
                            >
                                <div className="">
                                    {item.name}
                                </div>
                            </NavLink>
                        ))}
                        <div>
                            <div className="flex items-center py-2 cursor-pointer" onClick={() => this.setState({ langOpen: !this.state.langOpen })}>
                                <button className="text-[#6D6D6D]"><img src={this.props.language.flag} alt="" /></button>
                                <div className="block" >{this.state.langOpen ? <ChevronUpIcon className="w-6 h-auto cursor-pointer ml-2 text-black" /> : <ChevronDownIcon className="w-6 h-auto cursor-pointer ml-2 text-black" />}</div>
                            </div>
                            <div className={`${this.state.langOpen ? "flex flex-col" : "hidden"}  py-2 bg-c-white rounded absolute z-40`}>
                                {this.props.languages.map(item => (
                                    <button key={item.lang} onClick={() => { this.props.changeLang(item.lang); this.setState({ langOpen: !this.state.langOpen }) }} className={`${this.props.language.lang === item.lang && "bg-gray-300 text-white font-semibold"} px-4 py-2 rounded font-medium whitespace-nowrap`}>
                                        <img src={item.flag} alt="" />
                                    </button>
                                ))}
                            </div>
                        </div>

                    </div>
                    {this.state.open && <div className="bg-gradient-to-r from-gray-900 to-gray-400 absolute top-14 bottom-0 w-8/12 left-0 opacity-70 z-10 animate-fade" onClick={() => this.setState({ open: !this.state.open })} />}
                </div>
            </div>
        )
    }
}