import HeaderImage from '../images/header-5.png'
import K1 from '../images/kontakt/kon1.svg'
import K2 from '../images/kontakt/kon2.svg'
import K3 from '../images/kontakt/kon3.svg'

const translations = {
    si: {
        header: { name: 'Kontakt', text: 'Kontakt', subtext: 'Veseli smo vsake nove stranke! Kontaktirajte nas preko spodnjega obrazca in z veseljem vam bomo odgovorili.', image: HeaderImage, button: "" },
        body: {
            s1: {
                h1: "Pošljite povpraševanje",
                t1: "Ime*",
                t2: "Priimek*",
                t3: "E-mail*",
                t4: "Ime podjetja",
                t5: "Sporočilo*",
                send: "Pošlji",
                resp1: "Sporočilo uspešno poslano!",
                resp2: "Napaka v pošiljanju sporočila."
            },
            s2: {
                t1: "Pokličite nas",
                t2: "Pišite nam",
                t3: "Socialna omrežja"
            },
            s3: {
                t1: "Delovni čas:",
                t2: "pon - pet : od 6:00 do 14:00"
            },
            s4: [
                { title: 'Direktor', name: 'Sebastijan Tehovnik', phone: '+386 (0) 41 674 975', phoneHref: '+38641674975', mail: 'info@tehovnik.si', image: K1 },
                { title: 'Direktor', name: 'Andraž Tehovnik', phone: '+386 (0) 41 362 030', phoneHref: '+38641362030', mail: 'info@tehovnik.si', image: K2 },
                { title: 'Računovodstvo in finance', name: 'Marina Marinšek Tehovnik', phone: '+386 (0) 31 556 921', phoneHref: '+38631556921', mail: 'marina@tehovnik.si', image: K3 },
            ]
        }
    },
    en: {
        header: { name: 'Contact', text: 'Contact', subtext: 'We welcome every new customer! Contact us using the form below and we will be happy to answer you.', image: HeaderImage, button: "" },
        body: {
            s1: {
                h1: "Send an inquiry",
                t1: "First Name*",
                t2: "Last Name*",
                t3: "E-mail*",
                t4: "Company name",
                t5: "Message*",
                send: "Send",
                resp1: "Message successfully sent!",
                resp2: "Error sending message."
            },
            s2: {
                t1: "Call us",
                t2: "Write to us",
                t3: "Social networks"
            },
            s3: {
                t1: "Working time:",
                t2: "mon - fri : from 6:00 to 14:00"
            },
            s4: [
                { title: 'Director', name: 'Sebastijan Tehovnik', phone: '+386 (0) 41 674 975', phoneHref: '+38641674975', mail: 'info@tehovnik.si', image: K1 },
                { title: 'Director', name: 'Andraž Tehovnik', phone: '+386 (0) 41 362 030', phoneHref: '+38641362030', mail: 'info@tehovnik.si', image: K2 },
                { title: 'Accounting and finance', name: 'Marina Marinšek Tehovnik', phone: '+386 (0) 31 556 921', phoneHref: '+38631556921', mail: 'marina@tehovnik.si', image: K3 },
            ]
        }
    },
    de: {
        header: { name: 'Kontakt', text: 'Kontakt', subtext: 'Wir freuen uns über jeden neuen Kunden! Kontaktieren Sie uns über das untenstehende Formular und wir werden Ihnen gerne antworten.', image: HeaderImage, button: "" },
        body: {
            s1: {
                h1: "Senden Sie eine Anfrage",
                t1: "Name*",
                t2: "Nachname*",
                t3: "E-mail*",
                t4: "Name der Firma",
                t5: "Nachricht*",
                send: "Senden",
                resp1: "Nachricht erfolgreich versendet!",
                resp2: "Fehler beim Senden der Nachricht."
            },
            s2: {
                t1: "Rufen Sie uns an",
                t2: "Schreib uns",
                t3: "Soziale Netzwerke"
            },
            s3: {
                t1: "Arbeitszeit:",
                t2: "mon - fri : von 6:00 bis 14:00 Uhr"
            },
            s4: [
                { title: 'Direktor', name: 'Sebastijan Tehovnik', phone: '+386 (0) 41 674 975', phoneHref: '+38641674975', mail: 'info@tehovnik.si', image: K1 },
                { title: 'Direktor', name: 'Andraž Tehovnik', phone: '+386 (0) 41 362 030', phoneHref: '+38641362030', mail: 'info@tehovnik.si', image: K2 },
                { title: 'Buchhaltung und Finanzen', name: 'Marina Marinšek Tehovnik', phone: '+386 (0) 31 556 921', phoneHref: '+38631556921', mail: 'marina@tehovnik.si', image: K3 },
            ]
        }
    }
}

export default translations