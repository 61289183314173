/* --- NPM --- */
import React, { Component } from 'react';
import MapComponent from "../components/map.component";

import Header from '../components/Header'
import K1 from '../images/kontakt/kon1.svg'
import K2 from '../images/kontakt/kon2.svg'
import K3 from '../images/kontakt/kon3.svg'
import SVG1 from '../images/kontakt/icon1.svg'
import SVG2 from '../images/kontakt/icon2.svg'
import Pointer from '../images/kontakt/pointer.svg'

import translations from "../translations/kontakt";

export default class Kontakt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      message: "",
      error: false,
      emailError: false,
      feedback: "",
      isButtonDisabled: false,
      header: {},
      body: {
        s1: {
          h1: "Pošljite povpraševanje",
          t1: "Ime*",
          t2: "Priimek*",
          t3: "E-mail*",
          t4: "Ime podjetja",
          t5: "Sporočilo*",
          send: "Pošlji",
          error: "Izpolnite vsa polja z *",
          resp1: "Sporočilo uspešno poslano!",
          resp2: "Napaka v pošiljanju sporočila."
        },
        s2: {
          t1: "Pokličite nas",
          t2: "Pišite nam",
          t3: "Socialna omrežja"
        },
        s3: {
          t1: "Delovni čas:",
          t2: "pon - pet : od 6:00 do 14:00"
        },
        s4: [
          { title: 'Direktor', name: 'Sebastijan Tehovnik', phone: '+386 (0) 41 674 975', phoneHref: '+38641674975', mail: 'info@tehovnik.si', image: K1 },
          { title: 'Direktor', name: 'Andraž Tehovnik', phone: '+386 (0) 41 362 030', phoneHref: '+38641362030', mail: 'info@tehovnik.si', image: K2 },
          { title: 'Računovodstvo in finance', name: 'Marina Marinšek Tehovnik', phone: '+386 (0) 31 556 921', phoneHref: '+38631556921', mail: 'marina@tehovnik.si', image: K3 },
        ]
      }
    }
    this.changeValue = this.changeValue.bind(this)
    this.sendMsg = this.sendMsg.bind(this)
  }

  componentDidMount() {
    switch (this.props.language.lang) {
      case 'si':
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
      case 'en':
        this.setState({ header: translations.en.header, body: translations.en.body })
        break;
      case 'de':
        this.setState({ header: translations.de.header, body: translations.de.body })
        break;
      default:
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      switch (this.props.language.lang) {
        case 'si':
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
        case 'en':
          this.setState({ header: translations.en.header, body: translations.en.body })
          break;
        case 'de':
          this.setState({ header: translations.de.header, body: translations.de.body })
          break;
        default:
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
      }
    }
  }

  changeValue(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  async sendMsg() {

    this.setState({
      isButtonDisabled: true
    })

    const {
      firstName,
      lastName,
      email,
      company,
      message
    } = this.state

    
      let comp = company
      if (company === "") {
        comp = "----"
      }

      const response = await fetch('/api/sporocila', {
        method: "POST",
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          company: comp,
          message: message
        })
      })
      const body = await response.json()
      if (body.success) {
        this.setState({
          feedback: "true"
        })
      } else {
        this.setState({
          feedback: "false"
        })
      }
    

    setTimeout(() => this.setState({ isButtonDisabled: false, feedback: "" }), 30000)

  }

  render() {
    return (
      <div className="min-h-screen">
        <Header
          header={this.state.header}
        />
        <div className="md:mx-40 mx-5">

          <div className="flex flex-col lg:flex-row mt-20 justify-between" data-aos="fade-in">
            <div className="w-full lg:w-2/3">
              <h1 className="font-bold text-xl">{this.state.body.s1.h1}</h1>

              <div className="flex flex-row w-full gap-5 justify-between">
                <div className="w-full mt-5">
                  <p className="">{this.state.body.s1.t1}</p>
                  <input className="h-10 border rounded border-black pl-2 w-full" onChange={this.changeValue} name="firstName" value={this.state.firstName} />
                </div>
                <div className="w-full mt-5">
                  <p className="">{this.state.body.s1.t2}</p>
                  <input className="h-10 border rounded border-black pl-2 w-full" onChange={this.changeValue} name="lastName" value={this.state.lastName} />
                </div>
              </div>

              <div className="mt-5">
                <p className="">{this.state.body.s1.t3}</p>
                <input className="h-10 border rounded border-black pl-2 w-full" onChange={this.changeValue} name="email" value={this.state.email} />
              </div>

              <div className="mt-5">
                <p>{this.state.body.s1.t4}</p>
                <input className="h-10 border rounded border-black pl-2 w-full" onChange={this.changeValue} name="company" value={this.state.company} />
              </div>

              <div className="mt-5">
                <p className="">{this.state.body.s1.t5}</p>
                <textarea className="border rounded border-black pl-2 w-full h-48" onChange={this.changeValue} name="message" value={this.state.message} />
              </div>
              <div className="flex flex-row float-right w-fit gap-5">
                {this.state.feedback === "false" && <p className="text-red-500 mt-5">{this.state.body.s1.resp2}</p>}
                {this.state.feedback === "true" && <p className="text-green-500 mt-5">{this.state.body.s1.resp1}</p>}
                <button className={`${(this.state.firstName && this.state.lastName && this.state.email && this.state.message) ? "opacity-full" : "opacity-50 cursor-default"} bg-black text-white px-8 py-3 rounded mt-5`} onClick={(this.state.firstName && this.state.lastName && this.state.email && this.state.message) ? this.sendMsg : null} disabled={this.state.isButtonDisabled}>{this.state.body.s1.send}</button>

              </div>

            </div>
            <div className="mt-10 lg:mt-0">
              <h1 className="font-bold text-xl pb-5">{this.state.body.s2.t1}</h1>
              <a href="tel:+386037593880" className="">+386 37 593 880</a><br />
              <h1 className="font-bold text-xl mt-10 pb-5">{this.state.body.s2.t2}</h1>
              <a href="mailto:info@tehovnik.si">info@tehovnik.si</a>
              <h1 className="font-bold text-xl mt-10">{this.state.body.s2.t3}</h1>
              <div className="flex flex-row gap-10 mt-10">
                {/* <a><img src={SVG1} alt="" /></a> */}
                <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/tehovnik-d-o-o/"><img src={SVG2} alt="" /></a>
              </div>

            </div>
          </div>

          <div className="flex flex-col lg:flex-row mt-24 justify-between gap-20" data-aos="fade-in">
            <div className="flex flex-col justify-between">
              <img src={Pointer} alt="" className="w-[7rem] h-auto" />
              <div className="">
                <p className="text-3xl mt-5 font-semibold">TEHOVNIK d.o.o.</p>
                <p className="font-medium text-sm mt-5">
                  Spodnje Preloge 21,<br />
                  3210 Slovenske Konjice<br />
                  Slovenija
                </p>
                <p className="font-medium text-sm mt-5">
                  {this.state.body.s3.t1}<br />
                  {this.state.body.s3.t2}
                </p>
              </div>

            </div>
            <div className="h-full w-full lg:w-2/3">


              <MapComponent />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between my-48 gap-10">
            {this.state.body.s4.map(item => (
              <div
                key={item.name}
                className="relative lg:w-60"
              >
                <div className="w-32 h-32 relative left-1/2 transform -translate-x-1/2"><img src={item.image} alt="" /></div>
                <p className="text-center mt-5 font-medium text-lg">{item.title}</p>
                <p className="text-center text-sm mt-2">{item.name}</p>
                <p className="text-center text-sm mt-2"><a href={`tel:${item.phoneHref}`}>{item.phone}</a></p>
                <p className="text-center text-sm mt-2"><a href={`mailto:${item.mail}`}>{item.mail}</a></p>
              </div>
            ))}
          </div>

        </div>
      </div>
    )
  }
}