import React, { useState } from "react";
import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import { FaMapMarkerAlt } from 'react-icons/fa';

const MapComponent = () => {


    const containerStyle = {
        width: "100%",
        height: "400px",
    }

    const center = {
        lat: 46.34866649568658,
        lng: 15.404327115205271
    }

    const [show, setShow] = useState(false);

    return (
        
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
            >

                <MarkerF
                    icon={FaMapMarkerAlt}
                    position={center}
                    onClick={() => setShow(true)}
                />
                {show &&
                    <InfoWindow
                        position={center}
                        onCloseClick={() => setShow(false)}
                    >
                        <div>
                            <h1 className="font-medium text-[14px]">TEHOVNIK d.o.o., ZOBNIKI,<br />
                            struženje, rezkanje,<br />
                            ozobljenje ...</h1>
                            <p>Spodnje Preloge 21,<br />
                                3210 Slovenske Konjice<br />
                                Slovenija</p>
                            
                            <a className="text-c-blue hover:underline" rel="noreferrer" target="_blank" href="https://maps.google.com/maps?ll=46.348669,15.404327&amp;z=15&amp;t=m&amp;hl=sl-SI&amp;gl=US&amp;mapclient=apiv3&amp;cid=12994958974210528399"> <span>Prikaži v Google Zemljevidih</span> </a>
                        </div>
                    </InfoWindow>}


            </GoogleMap>
        
    );
};

export default MapComponent;