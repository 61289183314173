/* --- NPM --- */
import React, { Component } from 'react';

import Header from '../components/Header'

import I1 from '../images/izdelki/izd1.svg'
import I2 from '../images/izdelki/izd2.svg'
import I3 from '../images/izdelki/izd3.svg'
import I4 from '../images/izdelki/izd4.svg'
import I5 from '../images/izdelki/izd5.svg'
import I6 from '../images/izdelki/izd6.svg'

import S1 from '../images/izdelki/str1.svg'
import S2 from '../images/izdelki/str2.svg'
import S3 from '../images/izdelki/str3.svg'

import translations from '../translations/izdelki'

export default class Izdelki extends Component {
  constructor(props) {
    super(props)
    this.state = {
      header: {},
      body: {
        s1: [
          { image: I1, title: 'Cilindrični zobniki', sub1: 'Zunanje ravno in poševno ozobljeni', m1: '(max fi=600, m=12)', sub2: 'Notranje ravno ozobljeni', m2: '(max fi=1000, m=12)' },
          { image: I2, title: 'Zobate jermenice', sub1: 'Metrične', m1: '(max fi=600)', sub2: 'Colske', m2: '(max fi=600)' },
          { image: I3, title: 'Zobate letve', sub1: 'Ravno ozobljene', m1: '(150x150x2000, m=18)', sub2: 'Poševno ozobljene', m2: '(150x150x2000, m=18)' },
          { image: I4, title: 'Spiralno stožna dvojica', sub1: 'Ravno ozobljeni', m1: '(max fi=250, m=6)', sub2: 'Spiralno ozobljeni', m2: '(max fi=500, m=10)' },
          { image: I5, title: 'Gredi in osovine', sub1: 'Ravno žlebovi', m1: '(fi=230×1500)', sub2: 'Evolventni žlebovi', m2: '(fi=230×1500)' },
          { image: I6, title: 'Polži in polžna kolesa', sub1: 'Polžna kolesa', m1: '(max fi=500, m=10)', sub2: 'Polži', m2: '(max fi=150, L=600, m=10)' }
        ],
        s2: {
          h1: "Olja in maziva",
          t1: "Za uporabo v pogonski tehniki in hidravličnih sklopih",
          h2: "Tehnični spreji",
          t2: "Za obnovo in vzdrževanje delov",
          h3: "Čistila",
          t3: "Za čiščenje talnih površin in orodja"
        }
      }
    }
  }

  componentDidMount() {
    switch (this.props.language.lang) {
      case 'si':
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
      case 'en':
        this.setState({ header: translations.en.header, body: translations.en.body })
        break;
      case 'de':
        this.setState({ header: translations.de.header, body: translations.de.body })
        break;
      default:
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      switch (this.props.language.lang) {
        case 'si':
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
        case 'en':
          this.setState({ header: translations.en.header, body: translations.en.body })
          break;
        case 'de':
          this.setState({ header: translations.de.header, body: translations.de.body })
          break;
        default:
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
      }
    }
  }

  render() {

    return (
      <div className="min-h-screen">
        <Header
          header={this.state.header}
        />
        <h1 className="font-medium text-3xl text-left mt-28 mx-5 md:mx-40" data-aos="fade-in">{this.state.body.h1}</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 auto-rows-fr mt-24 justify-between gap-x-[10vw] gap-y-[10vh] mx-5 md:mx-40 place-items-center sm:place-items-start" data-aos="fade-in">
          {this.state.body.s1.map(item => (
            <div
              key={item.title}
              className="xl:w-[18rem]"
            >
              <div className="w-full min-w-[12rem] max-w-[18rem] aspect-square bg-c-white relative rounded-2.5xl"><img src={item.image} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" alt="" /></div>
              <h1 className="font-semibold text-lg mt-5">{item.title}</h1>
              <p className="text-c-gray mt-2 text-xs">{item.sub1}</p>
              <p className="text-c-gray text-xs">{item.m1}</p>
              <p className="text-c-gray mt-2 text-xs">{item.sub2}</p>
              <p className="text-c-gray text-xs">{item.m2}</p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 auto-rows-fr mt-24 px-5 md:px-40 py-32 bg-[#F9F9F9] place-items-left lg:place-items-center auto-cols-fr">
          <div className="mb-20 lg:mb-0" data-aos="fade-in">
            <img src={S1} className="mb-16 ml-5" alt="" />
            <div className="border-l-[3px] border-black pl-5 h-32">
              <p className="font-extrabold text-xl sm:text-3xl">{this.state.body.s2.h1}</p>
              <p className="font-medium text-md sm:text-lg mb-5">{this.state.body.s2.t1}</p>
            </div>
          </div>

          <div className="mb-20 lg:mb-0" data-aos="fade-in">
            <img src={S2} className="mb-16 ml-5" alt="" />
            <div className="border-l-[3px] border-black pl-5 h-32">
              <p className="font-extrabold text-xl sm:text-3xl">{this.state.body.s2.h2}</p>
              <p className="font-medium text-md sm:text-lg mb-5">{this.state.body.s2.t2}</p>
            </div>
          </div>

          <div data-aos="fade-in">
            <img src={S3} className="mb-16 ml-5" alt="" />
            <div className="border-l-[3px] border-black pl-5 h-32">
              <p className="font-extrabold text-xl sm:text-3xl">{this.state.body.s2.h3}</p>
              <p className="font-medium text-md sm:text-lg mb-5">{this.state.body.s2.t3}</p>
            </div>
          </div>

        </div>

      </div>
    )
  }
}