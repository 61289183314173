/* --- NPM --- */
import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import Header from '../components/Header'
import HeaderImage1 from '../images/header-1-1.png'
import HeaderImage2 from '../images/header-1-2.png'
import HeaderImage3 from '../images/header-1-3.png'
import Zobnik from '../images/domov/zobnik.png'
import Logos from '../images/domov/RSES.png'
import Stranke from '../images/domov/stranke.png'

import translations from '../translations/domov'


export default class Domov extends Component {
  constructor(props) {
    super(props)
    this.state = {
      header: { name: 'Domov', text: 'Zagotavljanje kakovostnih tehnoloških rešitev za vaš uspeh.', subtext: '', image: HeaderImage1, button: "Preberi novice" },
      body: {
        s1: [
          { text: 'Izkušnje', subtext: 'Naše proizvode in storitve odlikujejo kvaliteta in natančnost, ki je rezultat strokovne usposobljenosti zaposlenih, znanja, dolgoletnih izkušenj in sodobne strojne opreme, ki jo uporabljamo.' },
          { text: 'Znanje', subtext: 'Smo zanesljiv naslov za izdelavo raznovrstnih produktov po vaši meri, prav tako pa nudimo tudi strokovno svetovanje, ki našim strankam omogoča, da ostajajo konkurenčne na današnjem trgu.' },
          { text: 'Vizija', subtext: 'Tudi v bodoče bomo vaš najbolj zanesljivi partner, saj si prizadevamo biti vodilni ponudnik tehnoloških rešitev in storitev, ki svoje stranke in kvaliteto vedno postavlja na prvo mesto.' }
        ],
        s2: {
          h1: "O podjetju",
          t1: "Podjetje Tehovnik d.o.o. je proizvodno in trgovsko podjetje z več kot 50-letnim delovanjem. Prvovrstno se ukvarja z izdelavo in obdelavo zobnikov ter ozobljenja. Nudi usluge struženja, brušenja, rezkanja in pehanja ozobljenja ter v povezavi z drugimi podjetji nudi termično obdelavo. Proizvodne kapacitete so prilagojene posamični proizvodnji ter manjšim serijam. V trgovskem delu Tehovnik d.o.o. nudi strankam in naročnikom olja, maziva ter razna tehnična sredstva, pri prodaji pa se podjetje ukvarja med drugim še s prodajo rabljenih strojev in naprav.",
          t2: "Podjetje izpolnjuje zadane cilje na področju modernizacije strojnega parka in s tem stremi k nudenju kakovostnejših končnih izdelkov in storitev. Po nekajkratni rasti je danes Tehovnik d.o.o. v prihodnost zazrto družinsko podjetje, ki temelji na štiridesetletni tradiciji, in prepoznaven naslov na domačem trgu za izdelavo zobnikov in raznoraznih strojnih elementov."
        },
        s3: {
          h1: 50,
          t1: "Let delovanja",
          h2: 11,
          t2: "Zaposlenih",
          h3: 50,
          t3: "Strojev",
          h4: 2000,
          t4: "Strank",
          h5: 1000,
          t5: "Projektov letno"
        },
        s4: {
          h1: "Naše stranke",
          t1: "Naši izdelki se uporabljajo v različnih panogah in zato sodelujemo s podjetji, ki delujejo na področjih kot so na primer strojna industrija, vzdrževanje in servisi, farmacevtska industrija, kemična industrija, grafična industrija, prehrambena industrija in kmetijstvo. Predani smo temu, da našim strankam vedno nudimo najvišjo kvaliteto izdelkov in storitev. Zato nam je v ponos, da nam zaupate in se vedno znova odločate za sodelovanje z nami."
        }
      },
      position: 0
    }

    this.switchImage = this.switchImage.bind(this)
  }

  componentDidMount() {
    switch (this.props.language.lang) {
      case 'si':
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
      case 'en':
        this.setState({ header: translations.en.header, body: translations.en.body })
        break;
      case 'de':
        this.setState({ header: translations.de.header, body: translations.de.body })
        break;
      default:
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
    }
    this.switchImage()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      switch (this.props.language.lang) {
        case 'si':
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
        case 'en':
          this.setState({ header: translations.en.header, body: translations.en.body })
          break;
        case 'de':
          this.setState({ header: translations.de.header, body: translations.de.body })
          break;
        default:
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
      }
    }
  }

  switchImage() {
    if (this.state.position === 2) {
      this.setState({ position: 0 })
    } else {
      this.setState({ position: this.state.position + 1 })
    }

    let images = [HeaderImage1, HeaderImage2, HeaderImage3]
    let newHeader
    switch (this.props.language.lang) {
      case 'si':
        newHeader = translations.si.header
        break;
      case 'en':
        newHeader = translations.en.header
        break;
      case 'de':
        newHeader = translations.de.header
        break;
      default:
        newHeader = translations.si.header
        break;
    }
    newHeader.image = images[this.state.position]
    this.setState({ header: newHeader })

    setTimeout(() => {
      this.switchImage()
    }, 5000)

  }

  render() {
    return (
      <div className="min-h-screen">
        <Header
          header={this.state.header}
          position={this.state.position}
        />

        <div className="grid grid-cols-1 lg:grid-cols-3 auto-rows-fr mt-24 justify-center gap-20 mx-5 md:mx-40">
          {this.state.body.s1.map(item => (
            <div className="pb-20 px-5 border-l-4 border-[#F5F5F5]" key={item.text} data-aos="fade-in">

              <div className="font-bold text-left text-xl my-4 text-[#424242]">
                {item.text}
              </div>
              <div className="font-normal text-left text-c-gray ">
                {item.subtext}
              </div>
            </div>
          ))}
        </div>
        <div className="relative mx-5 md:mx-40 flex-col 2lg:flex-row flex gap-[10vw] mt-48" data-aos="fade-in">
          <div className="max-w-1/2 z-10 relative">
            <h1 className="font-semibold text-3xl text-left text-[#424242]">{this.state.body.s2.h1}</h1>
            <p className="text-left mt-20">{this.state.body.s2.t1}</p>
            <p className="text-left mt-5">{this.state.body.s2.t2}</p>
            <img src={Logos} className="mt-10" alt="" />

          </div>
          <img src={Zobnik} alt="" className="w-full h-full" />
        </div>

        <VisibilitySensor>
          {({ isVisible }) => (
            <div className="grid grid-cols-2 lg:grid-cols-5 auto-rows-fr mt-24 px-5 md:px-40 py-10 bg-[#F9F9F9] place-items-center" data-aos="fade-in">
              <div className="border-l-[3px] border-black pl-5 w-32 mb-10 lg:mb-0">
                <p className="font-extrabold text-xl sm:text-3xl">{isVisible ? <CountUp end={this.state.body.s3.h1} duration={3} /> : null }+</p>
                <p className="font-medium text-md sm:text-lg mb-5 whitespace-nowrap">{this.state.body.s3.t1}</p>
              </div>
              <div className="border-l-[3px] border-black pl-5 w-32 mb-10 lg:mb-0">
                <p className="font-extrabold text-xl sm:text-3xl">{isVisible ? <CountUp end={this.state.body.s3.h2} duration={3} /> : null}</p>
                <p className="font-medium text-md sm:text-lg mb-5 whitespace-nowrap">{this.state.body.s3.t2}</p>
              </div>
              <div className="border-l-[3px] border-black pl-5 w-32">
                <p className="font-extrabold text-xl sm:text-3xl">{isVisible ? <CountUp end={this.state.body.s3.h3} duration={3} /> : null}+</p>
                <p className="font-medium text-md sm:text-lg mb-5 whitespace-nowrap">{this.state.body.s3.t3}</p>
              </div>
              <div className="border-l-[3px] border-black pl-5 w-32">
                <p className="font-extrabold text-xl sm:text-3xl">{isVisible ? <CountUp end={this.state.body.s3.h4} duration={3} /> : null}+</p>
                <p className="font-medium text-md sm:text-lg mb-5 whitespace-nowrap">{this.state.body.s3.t4}</p>
              </div>
              <div className="border-l-[3px] border-black pl-5 w-32">
                <p className="font-extrabold text-xl sm:text-3xl">{isVisible ? <CountUp end={this.state.body.s3.h5} duration={3} /> : null}+</p>
                <p className="font-medium text-md sm:text-lg mb-5 whitespace-nowrap">{this.state.body.s3.t5}</p>
              </div>
            </div>
          )}

        </VisibilitySensor>




        <div className="relative mx-5 md:mx-40 flex-col 2lg:flex-row flex gap-[10vw] mt-48 items-center mb-48" data-aos="fade-in">
          <div className="max-w-1/2 z-10 relative">
            <h1 className="font-semibold text-3xl text-left text-[#424242]">{this.state.body.s4.h1}</h1>
            <p className="text-left mt-20">{this.state.body.s4.t1}</p>


          </div>
          <img src={Stranke} alt="" className="w-full h-full max-w-[34rem]" />
        </div>

      </div>
    )
  }
}
