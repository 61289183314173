/* --- NPM --- */
import React, { Component } from 'react';

import Header from '../components/Header'
import S1 from '../images/storitve/str1.svg'
import S2 from '../images/storitve/str2.svg'
import S3 from '../images/storitve/str3.svg'
import S4 from '../images/storitve/str4.svg'
import S5 from '../images/storitve/str5.svg'

import video from '../images/Tehovnik_video.mp4'

import translations from '../translations/storitve'


export default class Storitve extends Component {
  constructor(props) {
    super(props)
    this.state = {
      header: {},
      body: {
        s1: [
          { title: 'Ozobljenje', subtext: 'Cilindrično, stožno, zobate jermenice, zobate letve, verižniki, polži in polžna kolesa', image: S1 },
          { title: 'Struženje', subtext: 'Klasično, CNC', image: S2 },
          { title: 'Rezkanje', subtext: 'Klasično, CNC', image: S3 },
          { title: 'Brušenje', subtext: 'Cilindrično, ploskovno, žlebovi, položne navojnice, rezilna orodja', image: S4 },
          { title: 'Pehanje utorov', subtext: 'Eno utorno, več utorno', image: S5 },
        ]
      }
    }
  }

  componentDidMount() {
    switch (this.props.language.lang) {
      case 'si':
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
      case 'en':
        this.setState({ header: translations.en.header, body: translations.en.body })
        break;
      case 'de':
        this.setState({ header: translations.de.header, body: translations.de.body })
        break;
      default:
        this.setState({ header: translations.si.header, body: translations.si.body })
        break;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      switch (this.props.language.lang) {
        case 'si':
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
        case 'en':
          this.setState({ header: translations.en.header, body: translations.en.body })
          break;
        case 'de':
          this.setState({ header: translations.de.header, body: translations.de.body })
          break;
        default:
          this.setState({ header: translations.si.header, body: translations.si.body })
          break;
      }
    }
  }

  render() {

    return (
      <div className="min-h-screen">
        <Header
          header={this.state.header}
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 auto-rows-fr my-24 justify-center gap-x-[5vw] gap-y-[10vh] mx-5 md:mx-40" data-aos="fade-in">
          {this.state.body.s1.map(item => (
            <div key={item.title} className="flex flex-row items-center rounded-2.5xl shadow-custom">
              <div className="w-[150px] min-w-[150px] aspect-square relative"><img src={item.image} className="w-full h-full" alt="" /></div>
              <div className="ml-[5%]">
                <h1 className="text-lg font-medium">{item.title}</h1>
                <p className="text-c-gray mt-2 text-xs mr-10">{item.subtext}</p>
              </div>

            </div>
          ))}
        </div>

        <div className="w-auto h-auto mx-5 md:mx-40 mb-20" data-aos="fade-in">
          <video className="" controls >
            <source src={video} type="video/mp4" />
          </video>
        </div>

      </div>
    )
  }
}